import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit, Output } from '@angular/core';
import { ActionTranslator } from 'src/app/models/action-translator';
import { AlertTranslator } from 'src/app/models/alert-translator';
import { DisconnectedMessageTranslator } from 'src/app/models/disconnected-message-translator';
import { ScheduleDayTranslator } from 'src/app/models/schedule-day-translator';
import { ScheduleItem } from 'src/app/models/schedule-item';
import { WiFiInfo } from 'src/app/models/wi-fi-info';
import { Debug } from 'src/app/services/debug';
declare var Neato: any;
declare var $:any;

@Component({
  selector: 'app-robot',
  templateUrl: './robot.component.html',
  styleUrls: ['./robot.component.scss']
})
export class RobotComponent implements OnInit, AfterViewInit {

  @Input() robot: any;
  robotConnected: boolean = false;
  prettyState: string = '';
  isDocked: boolean = false;
  batteryPercentage: string = '';
  batteryName: string = '';
  alert: string = '';
  error: string = '';
  canGoToBase: boolean = false;
  canPause: boolean = false;
  canResume: boolean = false;
  canStart: boolean = false;
  canStop: boolean = false;
  isScheduleEnabled: any;
  robotDisconnectedMessage: string;
  cleaningInfo: string;
  showJson: boolean = false;
  scheduleItems: Array<ScheduleItem> = null;
  wifiInfos: Array<WiFiInfo> = null;
  updatedDate: Date;
  constructor() { }

  ngOnInit() {
    var that = this;

    this.robot.onConnected = function (data) {
      Debug.info(that.robot.serial + " got connected");
      that.robotConnected = true;
      that.robotDisconnectedMessage = '';
      that.scheduleSetWiFi();

    };
    this.robot.onDisconnected = function (status, json) {
      Debug.info(that.robot.serial + " got disconnected");
      Debug.info(that.robot.serial + " got disconnected status", status);
      Debug.info(that.robot.serial + " got disconnected json", json);
      that.robotConnected = false;
      if (json && json.message) {
        that.robotDisconnectedMessage = DisconnectedMessageTranslator.translate(status, json.message, that.robot.name);
      }
      else {
        that.robotDisconnectedMessage = '';
      }
    };
    this.robot.onStateChange = function () {
      Debug.info(that.robot.serial + " got new state @ " + new Date() + " : ", that.robot.state);
      that.stateChanged();
    };

    this.robot.connect();

  }

  maps(){
    this.robot.generalInfo().done(function (data,data2, data3) {
      Debug.info('generalInfo', data);
      Debug.info('generalInfo', data2);
      Debug.info('generalInfo', data3);

//       battery:
// authorizationStatus: 1
// level: 2
// manufacturingDate: "2018-11-14"
// timeToEmpty: 65535
// timeToFullCharge: 120
// totalCharges: 36
// vendor: "Panasonic"
// __proto__: Object
// firmware: "4.5.3-189"
// model: "BotVacD4Connected"
// productNumber: "905-0499"
// serial: "GPC30419-0479b71b41ce"
    });

    this.robot.getWifiNetworks().done(function (data,data2, data3) {
      Debug.info('getWifiNetworks', data);
      Debug.info('getWifiNetworks', data2);
      Debug.info('getWifiNetworks', data3);
    });


    // connected: true
    // ipAddress: "192.168.178.81"
    // macAddress: "30-45-11-e5-6e-2b"
    // routerIpAddress: "192.168.178.81"
    // ssid: "Kiwi-TF"
    // strength: 1
    // subnetMask: "255.255.255.0"
  }

  ngAfterViewInit(){
    $('.modal').modal();
  }

  toggleJsonMode(){
    this.showJson = ! this.showJson;
  }

  stateChanged() {
    this.setPrettyStateAndAction();
    this.setBattery();
    this.setScheduleEnabled();
    this.setBatteryPercentage();
    this.setCleaningInfo();
    this.setActions();
    this.setMessages();
    this.setUpdatedDate();
  }
  setUpdatedDate() {
    this.updatedDate = new Date();
  }

  hasSchedules(){
    return (this.scheduleItems !== null && this.scheduleItems.length > 0);
  }

  hasWifis(){
    return this.wifiInfos != null && this.wifiInfos.length > 0;
  }

  scheduleSetWiFi() {
    var that = this;
    var interval;
    interval = setInterval(function(){
      if (that.robot.getWifiNetworks){
        clearInterval(interval);
        that.setWiFi();
      }
    }, 100);
  }

  setWiFi() {
    var that = this;
    that.wifiInfos = null;
    this.robot.getWifiNetworks().done(function (status, networks) {
      if (networks){
        var wifiInfos: Array<WiFiInfo> = [];
        for(var i = 0; i < networks.length;i++ ){
          wifiInfos.push(new WiFiInfo(
            networks[i].connected,
            networks[i].ipAddress,
            networks[i].ssid,
            ))
        }
        that.wifiInfos = wifiInfos;

      }
    });
  }

  scheduleSetSchedule() {
    var that = this;
    var interval;
    interval = setInterval(function(){
      if (that.robot.getSchedule){
        clearInterval(interval);
        that.setSchedule();
      }
    }, 100);

  }
  setSchedule() {
    var that = this;
    this.robot.getSchedule().done(function (status, schedule) {
      if (schedule){
        if (schedule.enabled && schedule.events){
          Debug.info('schedule.events', schedule.events);
          var scheduleItems: Array<ScheduleItem> = [];
          for(var i = 0; i < schedule.events.length;i++ ){
            scheduleItems.push(new ScheduleItem(
              ScheduleDayTranslator.translate(schedule.events[i].day),
              schedule.events[i].mode,
              schedule.events[i].startTime
              ))
          }
          that.scheduleItems = scheduleItems;
        }
      }
    });
  }

  setScheduleEnabled() {
    if (this.robot.state && this.robot.state.details) {
      if (! this.isScheduleEnabled && this.robot.state.details.isScheduleEnabled){
        this.scheduleSetSchedule();
      }
      this.isScheduleEnabled = this.robot.state.details.isScheduleEnabled;
    }
    else {
      this.isScheduleEnabled = false;
    }
  }

  setMessages() {
    if (this.robot.state && this.robot.state.error) {
      this.error = AlertTranslator.translate(this.robot.state.error);
    }
    else {
      this.error = '';
    }
    if (this.robot.state && this.robot.state.alert) {
      this.alert = AlertTranslator.translate(this.robot.state.alert);
    }
    else {
      this.alert = '';
    }
  }

  setActions() {
    if (this.robot.state && this.robot.state.availableCommands) {
      this.canGoToBase = this.robot.state.availableCommands.goToBase;
      this.canPause = this.robot.state.availableCommands.pause;
      this.canResume = this.robot.state.availableCommands.resume;
      this.canStart = this.robot.state.availableCommands.start;
      this.canStop = this.robot.state.availableCommands.stop;
    }
  }
  setBattery() {
    this.batteryName = "battery_unknown";
    if (this.robot.state && this.robot.state.details) {
      this.isDocked = this.robot.state.details.isDocked;
      if (this.robot.state.details.isCharging) {
        this.batteryName = "battery_charging_full";
      }
      else {
        this.batteryName = "battery_full";
      }
    }
    else {
      Debug.info('no battery details!');
    }
  }

  setBatteryPercentage() {
    if (this.robot.state && this.robot.state.details) {
      this.batteryPercentage = this.robot.state.details.charge + ' %';
    }
    else {
      this.batteryPercentage = null;
    }
  }
  setCleaningInfo() {
    if (this.robot.state && this.robot.state.state && this.robot.state.cleaning) {
      if (this.robot.state.state === 2) {
        var cleaningInfoTemp = "";
        switch (this.robot.state.cleaning.category) {
          case 1:
            cleaningInfoTemp += "manual, ";
            break;
          case 2:
            cleaningInfoTemp += "house, ";
            break;
          case 3:
            cleaningInfoTemp += "spot, ";
            break;
          case 4:
            cleaningInfoTemp += "map, ";
            break;
          default:
            cleaningInfoTemp += "";
        }
        switch (this.robot.state.cleaning.mode) {
          case 1:
            cleaningInfoTemp += "eco";
            break;
          case 2:
            cleaningInfoTemp += "turbo";
            break;
          default:
            cleaningInfoTemp += "";
        }
        if (this.robot.state.cleaning.navigationMode){
          switch (this.robot.state.cleaning.navigationMode) {
            case 1:
              cleaningInfoTemp += ", normal";
              break;
            case 2:
              cleaningInfoTemp += ", extra care";
              break;
            default:
              cleaningInfoTemp += ", deep";
          }
        }
        this.cleaningInfo = "Doing a " + cleaningInfoTemp + " cleaning";
      }
      else {
        this.cleaningInfo = "";
      }
    }
    else {
      this.cleaningInfo = "";
    }
  }

  setPrettyStateAndAction() {
    var prettyStateTemp = "";
    var prettyActionTemp = "";
    if (this.robot.state && this.robot.state.state) {
      var robot_state = this.robot.state.state;
      switch (robot_state) {
        case 1:
          prettyStateTemp = "idle";
          break;
        case 2:
          prettyStateTemp = "busy";
          break;
        case 3:
          prettyStateTemp = "paused";
          break;
        case 4:
          prettyStateTemp = "error";
          break;
        default:
          prettyStateTemp = "";
      }
    }
    if (this.robot.state && this.robot.state.action) {
      var robot_action = this.robot.state.action;
      prettyActionTemp = ActionTranslator.Translate(robot_action);

    }
    if (!prettyStateTemp && !prettyActionTemp) {
      this.prettyState = null;
    }
    else if (prettyStateTemp && !prettyActionTemp) {
      this.prettyState = this.robot.name + " is " + prettyStateTemp + "."
    }
    else if (!prettyStateTemp && prettyActionTemp) {
      this.prettyState = this.robot.name + " is " + prettyActionTemp + "."
    }
    else if (prettyStateTemp && prettyActionTemp) {
      this.prettyState =  this.robot.name + " is " + prettyStateTemp + ", " + prettyActionTemp + "."
    }
  }

  getImageName() {
    if (this.robot.model === "BotVacD4Connected") {
      return "./assets/BotVacD4Connected.png"
    }
    return "./assets/smarter-navigation.png";
  }

  goToBase() {
    this.robot.sendToBase();
  }
  pause() { this.robot.pauseCleaning(); }
  resume() { this.robot.resumeCleaning(); }
  start() {
    this.robot.startHouseCleaning({
      mode: Neato.Constants.TURBO_MODE,
      modifier: Neato.Constants.HOUSE_FREQUENCY_NORMAL,
      navigationMode: Neato.Constants.EXTRA_CARE_OFF
    });
  }
  stop() { this.robot.stopCleaning(); }

}
