export class AlertTranslator {
  static translate(alertText: string){
    if (! alertText){
      return '';
    }
    alertText = alertText.toLowerCase();
    if (alertText === 'maint_bumper_stuck'){
      return 'Check the main bumper, it is stuck.';
    } else  if (alertText === 'dustbin_full'){
      return 'The dustbin is full.'
    }
    return alertText;

  }
}
