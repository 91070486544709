import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ScheduleItem } from 'src/app/models/schedule-item';
declare var $:any;

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit,  AfterViewInit {
  @Input() scheduleItems : Array<ScheduleItem> = [];
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    $('.modal').modal();
  }

}
