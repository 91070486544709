// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.titleml {
  font-size: 36px;
  font-weight: bolder;
}

.titles {
  font-size: 20px;
  font-weight: bolder;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,mBAAA;AACF;;AACA;EACE,eAAA;EACA,mBAAA;AAEF","sourcesContent":[".titleml{\r\n  font-size:36px;\r\n  font-weight: bolder;\r\n}\r\n.titles{\r\n  font-size:20px;\r\n  font-weight: bolder;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
