import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'latestUpdate'
})
export class LastUpdatedPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === null || value === undefined){
      return '';
    }

    var result:string = 'Latest update ';
    var hour = value.getHours();
    if (hour < 10){
      result += '0';
    }
    result += hour + ":";

    var minute = value.getMinutes();
    if (minute < 10){
      result += '0';
    }
    result += minute + ":";

    var second = value.getSeconds();
    if (second < 10){
      result += '0';
    }
    result += second;
    return result;
  }
}
