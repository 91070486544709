import { Component, Input, OnInit } from '@angular/core';
import { WiFiInfo } from 'src/app/models/wi-fi-info';

@Component({
  selector: 'app-wi-fi',
  templateUrl: './wi-fi.component.html',
  styleUrls: ['./wi-fi.component.scss']
})
export class WiFiComponent implements OnInit {

  @Input()  wifiInfos: Array<WiFiInfo> = null;
  constructor() { }

  ngOnInit() {
  }

}
