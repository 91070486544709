import { AfterViewInit, Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit,AfterViewInit {
  currentDate = new Date();
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
  }

  version(){
    return "1.14";
  }
}
