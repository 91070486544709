import { Component, Input, OnInit } from '@angular/core';
import { Debug } from 'src/app/services/debug';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() user: any;
  @Input() userName: string;
  @Input() isLoggedIn: boolean;
  constructor() { }

  ngOnInit() {
    Debug.info('user in header', this.user);
  }

  logOff(){
    this.user.logout()
    .done(function (data) {
      Debug.info('logOff succeeded, data', data)
      window.location.href = environment.redirectUrl;
      //location.reload();
      Debug.info(window.location.origin);
    }).fail(function (data) {
      Debug.info('logOff failed, data', data)
  });
  }
}
