// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   key: "18e2443d7b5728e7b9a0b6555fbb7d2b3d02f9a97e1bd7ca48200344d5380518",
//   redirectUrl: 'https://localhost:4800/',
//   doLogModel: false
// };
export const environment = {
  production: true,
  key: '57994c8c09c44b9710fa0c4333d54460326e3958a236c3983e91f65654bfd7a8',
  redirectUrl: 'https://neato.affinno.nl',
  doLogModel : true
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
