import { AfterViewInit, Component } from '@angular/core';
import { AppState } from './models/app-state.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Debug } from './services/debug';
declare var Neato: any;
declare var $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{
  title = 'Neato';
  user: any;
  appState: AppState = AppState.Loading;
  userName: string;
  robots: Array<any>;
  robotsLoaded:boolean = false;

  constructor(private http: HttpClient) {
    this.user = new Neato.User();
    this.setUserstatus();
  }

  ngAfterViewInit(){
  }

  setUserstatus() {
    var that = this;
    if (this.UserHasToken()) {
      that.appState = AppState.LoggedInUserLoading;
      this.user.isConnected()
        .done(function () {
          that.getUserData();
          that.getRobotData();
          that.appState = AppState.LoggedIn;
        })
        .fail(function () {
          that.appState = AppState.FailedLogin;
        });
    }
    else {
      that.appState = AppState.LoggedOut;
    }
  }
  getUserData() {
    var that = this;
    this.user.getUserInfo()
    .done(function (data) {
      if (data.first_name){
        that.userName = data.first_name;
      }
    }).fail(function (data) {
     Debug.info('this.user.getUserInfo fail', data)
    });
  }
  getRobotData() {
    var that = this;
      this.user.getRobots()
      .done(function (robotsArray) {
        that.robots = robotsArray;
        var robot;
        //start polling robot state
        for (var i = 0; i < robotsArray.length; i++) {
          robot = robotsArray[i];
          Debug.info('robot', robot);
          Debug.info('robot.name', robot.name);
          that.logModel(robot.model);
        }
        that.robotsLoaded = true;
      }).fail(function (data) {
        Debug.info('this.user.getRobots() fail', data);
        that.robotsLoaded = true;
      });
  }
  logModel(model: any) {
    if (environment.doLogModel){
      this
      .http
      .post('https://neato.affinno.nl/log/log.php', { data: model })
      .subscribe((data: any) => Debug.info(data));
    }
  }

  UserHasToken() {
    return this.user.token;
  }

  isLoading(): boolean {
    return this.appState === AppState.Loading;
  }

  isLoggedOut(): boolean {
    return this.appState === AppState.LoggedOut;
  }
  isLoggedIn(): boolean {
    return this.appState === AppState.LoggedIn;
  }
  isFailedLoggedIn(): boolean {
    return this.appState === AppState.FailedLogin;
  }
  isLoggedInUserLoading(): boolean {
    return this.appState === AppState.LoggedInUserLoading;
  }
}
