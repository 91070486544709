export class ActionTranslator {
  static Translate(robot_action: any): string {
    var prettyActionTemp = '';
    switch (robot_action) {
      case 0: prettyActionTemp = "";
        break;
      case 1: prettyActionTemp = "house cleaning";
        break;
      case 2: prettyActionTemp = "spot cleaning";
        break;
      case 3: prettyActionTemp = "manual cleaning";
        break;
      case 4: prettyActionTemp = "docking";
        break;
      case 5: prettyActionTemp = "user menu active";
        break;
      case 6: prettyActionTemp = "suspended cleaning";
        break;
      case 7: prettyActionTemp = "updating";
        break;
      case 8: prettyActionTemp = "copying logs";
        break;
      case 9: prettyActionTemp = "recovering location";
        break;
      case 10: prettyActionTemp = "IEC test";
        break;
      case 11: prettyActionTemp = "map cleaning";
        break;
      case 12: prettyActionTemp = "exploring map (creating a persistent map)";
        break;
      case 13: prettyActionTemp = "acquiring persistent map id's";
        break;
      case 14: prettyActionTemp = "creating & uploading map";
        break;
      case 15: prettyActionTemp = "suspended exploration";
        break;
      default:
        prettyActionTemp = "";
    }
    return prettyActionTemp;
  }
}
