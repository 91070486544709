import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scheduleMode'
})
export class ScheduleModePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === 1){
      return 'eco';
    }

    return 'turbo';
  }

}
