import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { RobotComponent } from './components/robot/robot.component';
import { FooterComponent } from './components/footer/footer.component';
import { WaitSpinnerComponent } from './components/wait-spinner/wait-spinner.component';
import { APP_BASE_HREF } from '@angular/common';
import { NoRobotsComponent } from './components/no-robots/no-robots.component';
import { HttpClientModule } from '@angular/common/http';
import { ScheduleComponent } from './components/schedule/schedule.component';
import { ScheduleModePipe } from './pipes/schedule-mode.pipe';
import { PrivacyComponent } from './components/privacy/privacy.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WiFiComponent } from './components/wi-fi/wi-fi.component';
import { LastUpdatedPipe } from './pipes/latest-update.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WelcomeComponent,
    RobotComponent,
    FooterComponent,
    WaitSpinnerComponent,
    NoRobotsComponent,
    ScheduleComponent,
    ScheduleModePipe,
    PrivacyComponent,
    WiFiComponent,
    LastUpdatedPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: window['base-href']
    }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
