import { Component, Input, OnInit } from '@angular/core';
import { AppState } from 'src/app/models/app-state.enum';
import { Debug } from 'src/app/services/debug';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  clientId = environment.key;
  scopes = "control_robots+public_profile+maps";
  redirectUrl = environment.redirectUrl;

  @Input() user: any;
  @Input() appState: AppState;

  constructor() { }

  ngOnInit() {
  }

  login(){
    Debug.info('starting login in WelcomeComponent');
    Debug.info('this.redirectUrl',this.redirectUrl);

    this.user.login({
      clientId: this.clientId,
      scopes: this.scopes,
      redirectUrl: this.redirectUrl
    });

  }

  isFailedLogin(){
    return this.appState === AppState.FailedLogin;
  }
}
