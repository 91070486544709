export class DisconnectedMessageTranslator {
  static translate(status: number, message: string, robotname :string){
    if (! message){
      return '';
    }
    message = message.toLowerCase();
    if (message === 'timeout waiting a response from robot'){
      // 504
      return `Busy on the line getting updates from ${robotname}`;
    } else  if (message === 'could not find robot_serial for specified vendor_name') {
      //  404
      return `Busy on the line talking to ${robotname}`;
    } else  if (status === 0){
      return `Could not connect to ${robotname}. Could there be connection issues?`;
    }
    return message;

  }
}
